.App {
  text-align: center;
}

body {      
  height: 100%;              
  margin: 5% 5% 5% 5%;     
  background-color: dimgrey;  
  color: white;                
}


@media (max-width: 1280px) {
  body {
    margin: 8% 5% 5% 5%;   
  }
}

@media (max-width: 920px) {
  body {
    margin: 10% 5% 5% 5%;   
  }
}

@media (max-width: 768px) {
  body {
    margin: 20% 5% 5% 5%;   
  }
}

@media (max-width: 700px) {
  body {
    margin: 30% 5% 5% 5%;   
  }
}

@media (max-width:460px) {
  body {
    margin: 40% 5% 5% 5%;   
  }
}

@media (max-width: 320px) {
  body {
    margin: 40% 5% 5% 5%;   
  }
}

.header {
  margin-top: 3%;
  padding-top: 3%;
  padding-bottom: 3%;
}

@media (max-width: 768px) {
  .header {
    margin-top: 30%;
    padding-top: 20%;
  }
}

.card {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #dee2e6;
  color:black;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  box-shadow: 2px 2px 20px rgb(87, 87, 87);
}

.list-group {
  background-color: #dee2e6;
}


.chart-button {
  background-color: #dee2e6;
  color: dimgray;
}

.chart-button:hover {
  background-color: #cc7e63;
  color: white;
}

.chart-button:focus {
  background-color: dimgray;
  color: white;
}

.scrollable-dropdown {
  z-index: 2000;
  position: absolute;
  max-height: 300px;
  overflow-y: auto;
  overflow: "visible";
  width: 100%;
  left: auto;
}

.dropdown-container {
  position: relative;
}

.status-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.site-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.dropdown-size {
  min-width: 200px;
  max-height: 100%;
}

.input-width {
  max-width: 300px;
  max-height: 40px;
}


button {
  border: none;
  background-color: rgba(0, 0, 0, 0);
  width: 100%;
}

button:hover {
  border: none;
  background-color: rgba(0, 0, 0, 0);
}

button:active {
  border: none;
  color: white;
  background-color: rgba(0, 0, 0, 0);
}

.t-table {
  table-layout: fixed;
  width: 100%;
}

.t-table tr td, .t-table tr th{
  min-width: 150px;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.t-table tr {
  display: flex;
  flex-wrap: nowrap;
}

.th-site, .th-customer, .th-siteuid, .th-timestamp {
  width: 25%;
}

table {
  max-width: 100%;
}

.table-scroll {
  overflow-x: auto;
  overflow-y: auto;
  max-height: 400px;
}

.table-sticky th {
  position: sticky;
  color: white;
  top: 0;
  background: lightslategray;
  z-index: 1;
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
}

.table-sticky th:first-child,
.table-sticky td:first-child {
  position: sticky;
  color: black;
  left: 0;
  background-color: white; 
  z-index: 2;
} 

.table-sticky th:first-child {
  color: white;
  background: lightslategrey;
  z-index: 3;
} 

.t-table tr.error-row,
.t-table tr.error-row td {
  background-color: red !important;
}

.t-table tr.warning-row,
.t-table tr.warning-row td {
  background-color: yellow !important;
}

.input-error {
  background-color: #cc7e63;
  font-weight: bold;
}

.nav-space {
  padding-left: 10px;
  padding-right: 10px;
}




